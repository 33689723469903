import axios from 'axios';
import { BASE_URL } from '../apiUtils';
import { getAuthHeader} from '../apiUtils';
const discountCodeAPI = {
    getDiscountCodes() {
        return axios.get(`${BASE_URL}/discount`, { headers: getAuthHeader() });
    },
    updateDiscountCode(updatedData) {
        return axios.put(`${BASE_URL}/discount`, updatedData, { headers: getAuthHeader() });
      },
    addDiscountCode(updatedData) {
        return axios.post(`${BASE_URL}/discount`, updatedData, { headers: getAuthHeader() });
      },

    updateDiscountStatus(path) {
        return axios.put(`${BASE_URL}/discount/updateStatus?id=${path}`, null,{ headers: getAuthHeader() });
    },
};

export default discountCodeAPI;
