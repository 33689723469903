//store/modules/auth.js// store/modules/auth.js
import authAPI from '@/api/auth';
import { refreshToken } from '@/api/apiUtils'; // Import refreshToken from apiUtils

const state = {
  isAuthenticated: !!localStorage.getItem('jwtToken'),
};

const mutations = {
  SET_AUTHENTICATED(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
};

const actions = {
  async loginUser({ commit }, { username, password }) {
    const response = await authAPI.login(username, password);
    localStorage.setItem('jwtToken', response.jwtToken);
    const configData = {
      masterRolesId: response.masterRolesId,
      roleName: response.roleName,
      admin: response.admin,
      fullName: response.fullName,
      userId: response.userId,
      emailId: response.emailId,
      countryId: response.countryId,
      stateId: response.stateId,
      cityId: response.cityId,
      primaryMobile: response.primaryMobile,
      secondaryMobile: response.secondaryMobile,
    };
    localStorage.setItem('config', JSON.stringify(configData));
    localStorage.setItem('allowedPages', response.allowedPages);
    commit('SET_AUTHENTICATED', true);
    return response;
  },
  logoutUser({ commit }) {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('allowedPages');
    localStorage.removeItem('config')
    commit('SET_AUTHENTICATED', false);
  },
  async refreshToken({ commit }) {
    try {
      const token = await refreshToken();
      localStorage.setItem('jwtToken', token);
      commit('SET_AUTHENTICATED', true);
      return token;
    } catch (error) {
      console.error('Refresh token failed:', error);
      localStorage.removeItem('jwtToken');
      commit('SET_AUTHENTICATED', false);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
