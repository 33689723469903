<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Orders</h4>
      </div>
    </div>
    <div class="row pt-4 pt-xxl-5" style="position:relative">
      <!-- Country Dropdown -->
      <div v-if="configData.masterRolesId !== '2' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label style="margin-top:5px;">Select Country:</label>
        <select v-model="selectedCountry" @change="fetchStates(selectedCountry)"
                class="form-select"
                :disabled="configData.masterRolesId !== '1' ">
          <option value="0">ALL</option>
          <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
            {{ country.countryName }}
          </option>
        </select>
      </div>

      <!-- State Dropdown -->
      <div v-if="configData.masterRolesId !== '2' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label style="margin-top:5px;">Select State:</label>
        <select v-model="selectedState" @change="fetchCities(selectedCountry,selectedState)" id="stateDropdown"
                class="form-select"
                :disabled="configData.masterRolesId !== '1' ">
          <option value="0">ALL</option>
          <option v-for="state in states" :key="state.stateID" :value="state.stateId">{{ state.stateName }}</option>
        </select>
      </div>

      <div v-if="configData.masterRolesId !== '2' "
           class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label style="margin-top:5px;">Select City:</label>
        <select v-model="selectedCity" @change="fetchStores(selectedCountry,selectedState,selectedCity)"
                class="form-select"
                :disabled="(configData.masterRolesId !== '1' && configData.masterRolesId !== '4')">
          <option value="0">ALL</option>
          <option v-for="city in cities" :key="city.cityId" :value="city.cityId">
            {{ city.cityName }}
          </option>
        </select>
      </div>

      <!-- Store Dropdown -->
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label style="margin-top:5px;">Select Store:</label>
        <select v-model="selectedStore" id="storeDropdown" class="form-select">
          <option value="0">ALL</option>
          <option v-for="store in stores" :key="store.storeId" :value="store.storeId">{{ store.storeName }}</option>
        </select>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label style="margin-top:5px;">Start Date</label>
        <input type="date" v-model="startDate" class="form-control control-fr" id="startDate">
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
        <label for="endDate" style="margin-top:5px;">End Date</label>
        <input type="date" v-model="endDate" class="form-control control-fr" id="endDate">
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2" style="margin-top:25px;">
        <button @click="fetchOrderPayments" class="btn btn-success">Submit</button>
      </div>

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <!--        <button @click="openEditModal(null,false)" class="btn btn-success" style="position:absolute;right:30px;">
                  Add
                </button>-->

        <table ref="ordersTable" class="table mt-3">
          <thead>
          <tr>
            <th>Order Id</th>
            <th>Order Status</th>
            <th>Order Amount</th>
            <th>Order By</th>
            <th>Store Name</th>
            <th>Service Type</th>
            <th>Discount Code</th>
            <th>Discount Value</th>
            <th>Order Quantity</th>
            <th>View</th>
            <th v-if="configData.masterRolesId !== '2' ">Store Edit</th>
            <th>Order Status Edit</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="order in orders" :key="order.orderId">
            <td>{{ order.orderId }}</td>
            <td>{{ order.orderStatus }}</td>
            <td>{{ order.orderAmount }}</td>
            <td>{{ order.orderBy }}</td>
            <td>{{ order.storeName }}</td>
            <td>{{ order.serviceType }}</td>
            <td>{{ order.discountCode }}</td>
            <td>{{ '₹'+order.discountAmount.toFixed(2) }}</td>
            <td>{{ order.orderQuantity }}</td>
            <td><img :src="require('@/assets/images/eye.png')" @click="fetchOrderDetails(order.orderId)" width="25"
                     height="25" class="btn-tbl-img1" alt=""></td>
            <td v-if="configData.masterRolesId !== '2' ">
              <div class="three-images d-flex">

                <a @click="openEditModal(order,'store')">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                </a>
              </div>
            </td>
            <td>
              <div class="three-images d-flex">
                <a @click="openEditModal(order,'orderStatus')">
                  <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1" alt="">
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>


        <div id="dataModal" class="modal">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Edit Header -->
              <div class="modal-header">
                <h5 v-if="editType==='orderStatus'" class="modal-title">Order Status Edit</h5>
                <h5 v-if="editType==='store'" class="modal-title">Store Edit</h5>
                <button type="button" class="btn btn-danger" @click="closeEditModal">
                  <span>&times;</span>
                </button>
              </div>
              <!-- Edit Body -->
              <div class="modal-body">
                <label v-if="editType==='orderStatus'" class="right-content"> Order Status </label>
                <select v-if="editType==='orderStatus'" v-model="updatedOrderStatus" class="form-select">
                  <option v-for="orderStatus in orderStatus" :key="orderStatus.id" :value="orderStatus.id">
                    {{ orderStatus.status }}
                  </option>
                </select>
                <label v-if="editType==='store'" class="right-content"> Store Name </label>
                <select v-if="editType==='store'" v-model="updatedStoreId" class="form-select">
                  <option v-for="store in stores" :key="store.storeId" :value="store.storeId">
                    {{ store.storeName }}
                  </option>
                </select>
              </div>
              <!-- Edit Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeEditModal">Close</button>
                <button type="button" class="btn btn-primary" @click="updateOrderStatus">Save Changes</button>
              </div>
            </div>
          </div>
        </div>


        <div class="modal fade" id="viewModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed !important; overflow: auto !important; background-color: rgba(0, 0, 0, 0.4) !important;">
          <div class="modal-dialog modal-lg">
            <div class="modal-content" style="overflow-y: auto;max-height: 90vh;">
              <button type="button" class="btn-close ms-auto button_close" aria-label="Close" @click="closeViewModal">

              </button>

              <div class="modal-header modal_head d-block text-center">
                <p>ORDER DETAILS</p>
                <h1 class="dead-text">{{ viewOrder.createdTime }}</h1>
                <p>Order#{{ viewOrder.orderId }} is currently <b>{{ viewOrder.orderStatus }}</b></p>
              </div>
              <div class="modal-body">
                <div class="product-modal d-flex">
                  <p>ITEMS</p>
                  <P class="ms-auto">TOTAL</P>
                </div>
                <hr>
                <div class="product-modal d-flex mt-4" v-for="items in viewOrder.orderItems">
                  <div class="d-flex fruit"><p>{{ items.itemQuantity }}x </p><img :src="items.itemImage"
                                                                                class="img-fluid">
                    <p>{{ items.itemName }}</p></div>
                  <P class="ms-auto price">₹{{ parseFloat(items.itemAmount).toFixed(2) }}</P>
                </div>
                <div class="product-modal-text d-flex mt-5" v-if="viewOrder.orderSubTotal !== null">
                  <p>{{ 'Sub Total'}}</p>
                  <P class="ms-auto price">₹{{ parseFloat(viewOrder.orderSubTotal).toFixed(2) }}</P>
                </div>
                <div class="product-modal-text d-flex" v-if="discountAmount !== 0">
                  <p>{{ 'Discount Applied ('+ discountCode +')'}}</p>
                  <p class="ms-auto price">- ₹{{ discountAmount.toFixed(2) }}</p>
                </div>
                <div class="product-modal-text d-flex" v-for="tax in viewOrder.orderTaxes">
                  <p>{{ tax.taxName }}</p>
                  <P class="ms-auto price">₹{{ tax.taxAmount }}</P>
                </div>

                <hr>
                <div class="product-modal-text product-modal-text1 d-flex my-2">
                  <p class="mb-0">Total</p>
                  <P class="ms-auto price mb-0">₹{{ viewOrder.orderAmount }}</P>
                </div>
                <hr>
                <div class="product-modal-text d-flex mt-1 mt-4">
                  <p>Payment Method</p>
                  <P class="ms-auto price">{{ viewOrder.paymentMode }}</P>
                </div>
                <div class="product-modal-text d-flex mt-1">
                  <p>Transection ID</p>
                  <P class="ms-auto price">{{ viewOrder.paymentTransactionId }}</P>
                </div>
                <div class="product-modal-text d-flex mt-1 mb-2">
                  <p>Payment Status</p>
                  <P class="ms-auto price">{{ viewOrder.paymentStatus }}</P>
                </div>
                <hr>
                <h4 class="pickup">Pickup Details</h4>
                <div class="product-modal-text d-flex mt-1">
                  <p class="mb-0">Pickup Date</p>
                  <P class="ms-auto price mb-0">{{ viewOrder.pickupDateTime }}</P>
                </div>
                <div class="product-modal-text d-flex mt-2">
                  <p>Delivery Date</p>
                  <P class="ms-auto price">{{ viewOrder.deliveredTime }}</P>
                </div>
                <hr>

                <h4 class="pickup">Measurement Details</h4>
                <div class="product-modal-text d-flex mt-2">
                  <p> Waist</p>
                  <P class="ms-auto price mb-0">{{ measurements.waist }}</P>
                </div>
                <div class="product-modal-text d-flex mt-2">
                  <p>Pallu</p>
                  <P class="ms-auto price">{{ measurements.pallu }}</P>
                </div>
                <div class="product-modal-text d-flex mt-2">
                  <p>Cross Body</p>
                  <P class="ms-auto price">{{ measurements.crossBody }}</P>
                </div>
                <div class="product-modal-text d-flex mt-2">
                  <p>cross Body With FullRound</p>
                  <P class="ms-auto price">{{ measurements.crossBodyWithFullRound }}</P>
                </div>
                <hr>
                <div class="product-modal-text3 d-flex mt-3 border p-3" v-if="viewOrder.orderAddress != null && viewOrder.orderAddress != ''">
                  <div class="adress2">
                    <p>Pickup Address</p>
                    <p>{{ viewOrder.orderAddress }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import orderPaymentAPI from '@/api/OrdersPayment/OrdersPayment';
import storeAPI from '@/api/store/store.js';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';
import countryAPI from "@/api/location/country";
import stateAPI from "@/api/location/state";
import cityAPI from "@/api/location/cities";

export default {
  data() {
    return {
      owners: [],
      orders: [],
      orderStatus: [],
      stores: [],
      selectedOwner: 0,
      startDate: '',
      endDate: '',
      editing: null,
      countries: [],
      states: [],
      cities: [],
      selectedCountry: 0,
      selectedState: 0,
      selectedCity: 0,
      selectedStore: 0,
      updatedOrderStatus: '',
      updatedStoreId: '',
      viewOrder: '',
      editType: '',
      measurements: '',
      discountCode: '',
      discountAmount: 0,
      configData: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : null,
    };
  },
  methods: {
    openEditModal(order, type) {
      this.editing = order;
      this.editType = type;
      const modal = document.getElementById('dataModal');
      if (this.editType === 'orderStatus') {
        this.updatedOrderStatus = this.editing.orderStatusId;
      }
      if (this.editType === 'store') {
        this.fetchStores(0,0,0);
        this.updatedStoreId = this.editing.storeId;
      }

      if (modal) {
        //modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeEditModal() {
      this.updatedOrderStatus = '';
      this.updatedStoreId = '';
      this.editing = '';
      const modal = document.getElementById('dataModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    closeViewModal() {
      this.viewOrder = '';
      const modal = document.getElementById('viewModal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async fetchCountries() {
      try {
        const response = await countryAPI.getCountries();
        this.countries = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching countries:', error);
        alert(error.response.data.message);
      }
    },
    async fetchStates(country) {
      try {
        const response = await stateAPI.getStates(country);
        this.states = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching states:', error);
        alert(error.response.data.message);
      }
    },
    async fetchCities(country, state) {
      try {
        const response = await cityAPI.getCities(country, state);
        this.cities = response.data.filter(record => record.active === true);
      } catch (error) {
        console.error('Error fetching cities:', error);
        alert(error.response.data.message);
      }
    },
    async fetchStores(country, state, city) {
      try {

        let path = "";
        if (country !== null && country !== 0) {
          path += `country_id=${country}`;
        }
        if (state !== null && state !== 0) {
          path += `&state_id=${state}`;
        }
        if (city !== null && city !== 0) {
          path += `&city_id=${city}`;
        }
        if (country === 'undefined') {
          return false;
        }
        if (this.configData.masterRolesId === '2') {
          path += `&owner_id=${this.configData.userId}`;
        }
        const response = await storeAPI.getStores(path, true);
        this.stores = response.data;

      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    },
    async fetchOrderStatus() {
      try {
        const response = await orderPaymentAPI.getOrderStatus();
        this.orderStatus = response.data;
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    }, async fetchOrderDetails(orderId) {
      try {
        const response = await orderPaymentAPI.getOrderDetails(orderId);
        this.viewOrder = response.data;
        this.measurements = this.viewOrder.measurements;
        if(this.viewOrder.discounts?.discountAmount != null){
        this.discountAmount = this.viewOrder.discounts.discountAmount;
        this.discountCode = this.viewOrder.discounts.discountCode;
        }
        const modal = document.getElementById('viewModal');
        if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    },
    async fetchOrderPayments() {
      try {
        if (!this.startDate) {
          const oneMonthAgo = new Date();
          oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
          this.startDate = oneMonthAgo.toISOString().split('T')[0];
        }
        if (!this.endDate) {
          this.endDate = new Date().toISOString().split('T')[0];
        }

        this.startDate = this.startDate.split('T')[0];
        this.endDate = this.endDate.split('T')[0];

        const formattedStartDate = this.startDate + ' 00:00:00';
        const formattedEndDate = this.endDate + ' 23:59:59';

        let path = "";
        if (this.startDate !== null) {
          path += `start_date=${formattedStartDate}`;
        }
        if (this.endDate !== null) {
          path += `&end_date=${formattedEndDate}`;
        }
     /*   if (this.selectedOwner !== null) {
          path += `&user_id=${this.selectedOwner}`;
        }*/
        if (this.selectedCountry !== null && this.selectedCountry !== 0) {
          path += `&country_id=${this.selectedCountry}`;
        }
        if (this.selectedState !== null && this.selectedState !== 0) {
          path += `&state_id=${this.selectedState}`;
        }
        if (this.selectedCity !== null && this.selectedCity !== 0) {
          path += `&city_id=${this.selectedCity}`;
        }

        if (this.selectedStore !== null && this.selectedStore !== 0) {
          path += `&store_id=${this.selectedStore}`;
        }


        if (this.configData.masterRolesId === '2') {
          path += `&owner_id=${this.configData.userId}`;
        }
        const response = await orderPaymentAPI.getOrders(path);
        this.orders = response.data;
        this.initializeDataTable();
      } catch (error) {
        console.error('Error fetching Orders:', error);
      }
    },
    async updateOrderStatus() {
      try {

        let response = '';
        if (this.editType === 'orderStatus') {
          response = await orderPaymentAPI.updateOrderStatus(this.editing.orderId, this.updatedOrderStatus);
        }
        if (this.editType === 'store') {
          response = await orderPaymentAPI.updateStoreName(this.editing.orderId, this.updatedStoreId);

        }

        if (response.data.success) {
          alert(response.data.message);
        }
        await this.fetchOrderPayments();
        this.closeEditModal();
      } catch (error) {
        console.error('Error Updating Orders:', error);
        alert(error.response.data.message);
      }
    },
    getExportTitle() {
      return 'Services';
    },
    initializeDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.ordersTable)) {
        $(this.$refs.ordersTable).DataTable().destroy();
      }
      let columnsToExport = [0, 1, 2];

      this.$nextTick(() => {
        $(this.$refs.ordersTable).DataTable({
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'csv',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'excel',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'pdf',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
            {
              extend: 'print',
              title: this.getExportTitle(),
              exportOptions: {
                columns: columnsToExport
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    if (this.configData.masterRolesId === '4') {
      this.fetchCountries();
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.configData.countryId, this.configData.stateId);
      this.fetchStores(this.selectedCountry, this.selectedState, null);
    } else if (this.configData.masterRolesId === '3') {
      this.fetchCountries();
      this.selectedCountry = this.configData.countryId;
      this.fetchStates(this.configData.countryId);
      this.selectedState = this.configData.stateId;
      this.fetchCities(this.configData.countryId, this.configData.stateId);
      this.selectedCity = this.configData.cityId;
      this.fetchStores(this.selectedCountry, this.selectedState, this.selectedCity);
    } else if (this.configData.masterRolesId === '1') {
      this.fetchCountries();
      this.selectedCountry = '1';
      this.fetchStates(this.selectedCountry);
    } else if (this.configData.masterRolesId === '2') {
      this.fetchStores(null, null, null);
    }
    this.fetchOrderStatus();
    this.fetchOrderPayments();


  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/*
body {
  font-family: "Poppins", sans-serif;
}*/

.modal_head {
  background-color: #deebfd;
}

.button_close {
  position: absolute;
  right: 0px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  opacity: 1;
  color: #fff;
}

.modal_head p:nth-child(1) {
  font-size: 15px;
  font-weight: 500;
  color: #ababab;
}

.modal_head p:nth-child(3) {
  font-size: 12px;
  font-weight: 400;
  color: #ababab;
}

.modal_head p:nth-child(3) b {
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

.modal_head h1 {
  font-size: 26px;
  font-weight: 600;
  color: #000;
  margin: -9px 0px 10px 0px;
}

.product-modal p {
  font-size: 16px;
  font-weight: 600;
  color: #5e5e5e;
  margin-bottom: 0px;
}

hr {
  margin: 5px 0px !important;
}

.fruit p:nth-child(1) {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.fruit img {
  background-color: #d6d6d6;
  border-radius: 50%;
  padding: 5px;
  width: 25px;
  height: 25px;
  margin: -6px 15px 0px 15px;
}

.fruit p:nth-child(3) {
  font-size: 16px;
  color: #82a7df;
  font-weight: 500;
}

.price {
  font-size: 18px;
  color: #000 !important;
}

.product-modal-text p:nth-child(1) {
  font-size: 16px;
  color: #5e5e5e;
  line-height: 1;
  font-weight: 400;
}

.product-modal-text p:nth-child(2) {
  font-size: 16px;
  color: #000;
  line-height: 1;
  font-weight: 500;
}

.product-modal-text1 p:nth-child(1), .product-modal-text1 p:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
}

.pickup {
  font-size: 18px;
  color: #000;
  line-height: 1;
  font-weight: 600;
  margin: 20px 0px 10px 0px;
}

.adress1 p:nth-child(1), .adress2 p:nth-child(1) {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.adress1 p:nth-child(2), .adress2 p:nth-child(2) {
  font-size: 16px;
  color: #5e5e5e;
  font-weight: 400;
}

.subsription p {
  font-size: 12px;
}

.modal-body {
  padding: 10px 30px 50px 30px;
}
</style>
