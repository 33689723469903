<template>
  <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12 main-colunm">
    <div class="d-flex">  
      <div class="dashboard-title ps-2 ps-md-0">
        <h4>Admins</h4>
      </div>
    </div> 
    <div class="row pt-4 pt-xxl-5" style="position:relative">

      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button  @click="openModal(null,'add')" class="btn btn-success" style="position:absolute;right:30px;">
                    Add
        </button>
        <!-- Admin Table using DataTables -->
        <table ref="adminDataTable" class="table mt-3">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Email ID</th>
              <th>Full Name</th>
              <th>Role Name</th>
              <th>Mobile Number</th>
              <th>Active</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="admin in admins" :key="admin.userId">
              <td>{{ admin.userId }}</td>
              <td>{{ admin.emailId }}</td>
              <td>{{ admin.fullName }}</td>
              <td>{{ admin.roleName }}</td>
              <td>{{ admin.primaryMobileNumber }}<br/>{{ admin.secondaryMobileNumber }}</td>
              <td>
                <p v-if="admin.active" class="ongoing" style="text-align:center">{{ admin.active }}</p>
                <p v-else class="progress1" style="text-align:center">false</p>
                </td>
              <td>
                <div class="three-images d-flex">
                  <a href="#" @click="openModal(admin,'edit')">
                    <img :src="require('@/assets/images/edit.png')" width="25" height="25" class="btn-tbl-img1">
                  </a>
                  <a href="#" @click="openModal(admin,'block')" v-if="admin.active">
                    <img :src="require('@/assets/images/block.png')" width="25" height="25" class="btn-tbl-img1">
                  </a>
                  <a href="#" @click="openModal(admin,'unblock')" v-else>
                    <img :src="require('@/assets/images/unlocked.png')" width="25" height="25" class="btn-tbl-img1">
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        

        <!--Customize modal-->
        <div id="modal" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  <span v-if="action==='edit'">
                    Edit Admin
                  </span>
                  <span v-if="action==='add'">
                    Add Admin
                  </span>
                  <span v-if="action==='block'">
                    Block Admin
                  </span>
                  <span v-if="action==='unblock'">
                    Unblock Admin
                  </span>
                </h5>
                <button class="btn btn-danger" @click="closeModal">X</button>
              </div>
              <div class="modal-body">
                <div v-if="action==='edit' || action==='add'">
                  <label class="right-content"> Full Name </label>
                  <input type="text" v-model="fullName" class="form-control" placeholder="Please Enter Full Name">
                  <label class="right-content"> Email Id </label>

                  <input type="text" v-model="emailId" class="form-control" placeholder="Please enter email">
                  <label class="right-content"> Primary MobileNumber </label>
                  <input type="text" v-model="primaryMobileNumber" class="form-control" placeholder="Please enter mobile">
                  <label class="right-content"> Secondary MobileNumber </label>
                  <input type="text" v-model="secondaryMobileNumber" class="form-control" placeholder="Please enter secondary mobile">

                  <label class="right-content"> Role</label>
                  <select v-model="updatedRole" class="form-select">
                    <option v-for="role in roles" :key="role.masterRoleId" :value="role.masterRoleId">{{ role.roleName }}</option>
                  </select>

                  <!-- Country Dropdown -->
                    <label v-if="this.updatedRole > 2" class="right-content" for="countryDropdown" style="margin-top:5px;">Select Country:</label>
                    <select v-if="this.updatedRole > 2"  v-model="selectedCountry" @change="fetchStates" id="countryDropdown" class="form-select">
                      <option v-for="country in countries" :key="country.countryId" :value="country.countryId">
                        {{ country.countryName }}
                      </option>
                    </select>

                  <!-- State Dropdown -->
                    <label v-if="this.updatedRole > 2" class="right-content" for="stateDropdown" style="margin-top:5px;">Select State:</label>
                    <select v-if="this.updatedRole > 2" v-model="selectedState" @change="fetchCities" id="stateDropdown" class="form-select">
                      <option v-for="state in states" :key="state.stateId" :value="state.stateId">{{ state.stateName }}</option>
                    </select>

                  <!-- city Dropdown -->
                    <label v-if="this.updatedRole > 2 && this.updatedRole === 3" class="right-content" for="cityDropdown" style="margin-top:5px;">Select City:</label>
                    <select v-if="this.updatedRole > 2 && this.updatedRole === 3" v-model="selectedCity" id="cityDropdown" class="form-select">
                      <option v-for="city in cities" :key="city.cityId" :value="city.cityId">{{ city.cityName }}</option>
                    </select>
                </div>
                <div v-if="action==='block' || action==='unblock'">
                  <span>
                    Are you sure you want to {{ action==='block'?'block':'unblock' }} {{ fullName }}?
                  </span>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                <button type="button" class="btn btn-primary" @click="modalSubmit">
                  <span v-if="action==='edit'">
                    Update
                  </span>
                  <span v-if="action==='add'">
                    Add
                  </span>
                  <span v-if="action==='block'">
                    Block
                  </span>
                  <span v-if="action==='unblock'">
                    Unblock
                  </span>
                </button>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userAPI from '@/api/user';
import rolesAPI from '@/api/roles/roles';
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'jszip/dist/jszip';
import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';
import countryAPI from "@/api/location/country";
import stateAPI from "@/api/location/state";
import cityAPI from "@/api/location/cities";

export default {
  data() {
    return {
      editingObject: null,
      fullName: '',
      emailId:'',
      primaryMobileNumber:'',
      secondaryMobileNumber:'',
      updatedRole: 0,
      selectedCity: 0,
      selectedState: 0,
      selectedCountry: 0,
      admins: [],
      roles: [],
      countries: [],
      states: [],
      cities: [],
      action:''
    };
  },
  methods: {
    openModal(admin,actionType) {
      this.action=actionType
      if(admin!==null){
        this.editingObject = admin;
        this.fullName = admin.fullName;
        this.emailId = admin.emailId;
        this.selectedCountry=admin.countryId;
        this.primaryMobileNumber=admin.primaryMobileNumber;
        this.secondaryMobileNumber=admin.secondaryMobileNumber;
        this.fetchStates();
        this.selectedState=admin.stateId;
        if (this.selectedState !== null && this.selectedState !== 0) {
          this.fetchCities();
          this.selectedCity = admin.cityId;
        }
        this.updatedRole= admin.roleId;
      }else{
        this.fullName='';
        this.emailId='';
        this.primaryMobileNumber='';
        this.secondaryMobileNumber='';
        this.updatedRole=0;
      }
      const modal = document.getElementById('modal');
      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeModal() {
      this.editingObject = null;
      this.fullName = '';
      this.updatedRole='';
      this.emailId = '';
      this.selectedCity = 0;
      this.selectedState = 0;
      this.selectedCountry = 0;
      const modal = document.getElementById('modal');
      if (modal) {
        modal.classList.remove('show');
        modal.classList.remove('hide');
         modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (modalBackdrop) {
          modalBackdrop.parentNode.removeChild(modalBackdrop);
        }
      }
    },
    async modalSubmit(){
      if(this.action==='edit' || this.action==='add'){
        if(this.fullName.trim()!=='' && this.emailId.trim()!==''){
          try{
            if(this.action==='edit'){
              const response = await userAPI.updateAdmin({
                fullName: this.fullName,
                emailId:this.emailId,
                roleId: this.updatedRole,
                primaryMobileNumber: this.primaryMobileNumber,
                secondaryMobileNumber: this.secondaryMobileNumber,
                countryId: this.selectedCountry ===0 ? null : this.selectedCountry,
                stateId: this.selectedState ===0 ? null : this.selectedState,
                cityId: this.selectedCity ===0 ? null : this.selectedCity,
              },this.editingObject.userId)
              alert(response.data.message);
              if (response.data.success) {
                await this.fetchAdmins(); // Refresh states after editing
                this.closeModal();
              }
            }
            else{
              const response = await userAPI.addAdmin({
                fullName:this.fullName,
                emailId:this.emailId,
                roleId: this.updatedRole,
                primaryMobileNumber: this.primaryMobileNumber,
                secondaryMobileNumber: this.secondaryMobileNumber,
              })
              alert(response.data.message)
              if(response.data.success){
                await this.fetchAdmins();
                this.closeModal();
              }
            }
          }catch(error){
            console.error("Error updating admin:",error);
          }
        }
        else{
          alert('Type both email ID and full Name')
        }
      }
      else if(this.action==='block' || this.action==='unblock'){
        try{
          const response = await userAPI.patchAdmin(this.editingObject.userId)
          alert(response.data.message)
          if(response.data.success){
            await this.fetchAdmins();
            this.closeModal();
          }
        } catch(error){
          console.error("error blocking admin:",error)
        }
      }
      
    },
    async fetchAdmins() {
      try {
        const response = await userAPI.getAdmins();
        this.admins = response.data;
        this.initializeDataTable();
        
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    },
    async fetchRoles() {
      try {
        const response = await rolesAPI.getRoles();
        this.roles = response.data;
        let i = this.roles.map((role) => role.masterRoleId).indexOf(2);
        this.roles.splice(i, 1);
        this.initializeDataTable();
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    },
    async fetchCountries() {
      try {
        const response = await countryAPI.getCountries();
        this.countries = response.data;
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },
    async fetchStates() {
      try {
        const response = await stateAPI.getStates(this.selectedCountry);
        this.states = response.data;
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    },
    async fetchCities() {
      try {
        const response = await cityAPI.getCities(this.selectedCountry, this.selectedState);
        this.cities = response.data;
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    },
    initializeDataTable() {
      // Destroy existing DataTable
      if ($.fn.DataTable.isDataTable(this.$refs.adminDataTable)) {
        $(this.$refs.adminDataTable).DataTable().destroy();
      }
      // Initialize DataTable when the component is mounted or when states change
      this.$nextTick(() => {
        $(this.$refs.adminDataTable).DataTable({
            mark:true,
          dom: 'Bfrtip',
          buttons: [
            {
              extend: 'copy',
              title: 'adminList',
              exportOptions: {
                columns: [0,1,2,3,4] // Exclude the 3rd column (0-based index)
              },
            },
            {
              extend: 'csv',
              title: 'adminList',
              exportOptions: {
                columns: [0,1,2,3,4] // Exclude the 3rd column (0-based index)
              },
            },
            {
              extend: 'excel',
              title: 'adminList',
              exportOptions: {
                columns: [0,1,2,3,4] // Exclude the 3rd column (0-based index)
              },
            },
            {
              extend: 'pdf',
              title: 'adminList',
              exportOptions: {
                columns: [0,1,2,3,4] // Exclude the 3rd column (0-based index)
              },
            },
            {
              extend: 'print',
              title: 'adminList',
              exportOptions: {
                columns: [0,1,2,3,4] // Exclude the 3rd column (0-based index)
              },
            },
          ],
          searchHighlight: true,
        });
      });
    },
  },
  created() {
    this.fetchAdmins();
    this.fetchRoles();
    this.fetchCountries();
  },
};
</script>
